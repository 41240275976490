/**
 * @generated SignedSource<<6f5611c36d410643e12d2ac09bd99a3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LinkAppearanceType = "BUTTON" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type HeroLink_contentBlock$data = {
  readonly appearanceType: LinkAppearanceType;
  readonly ariaLabel: string | null;
  readonly text: string;
  readonly url: string;
  readonly " $fragmentType": "HeroLink_contentBlock";
};
export type HeroLink_contentBlock$key = {
  readonly " $data"?: HeroLink_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroLink_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroLink_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appearanceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ariaLabel",
      "storageKey": null
    }
  ],
  "type": "Link",
  "abstractKey": null
};

(node as any).hash = "cccfb712adff2cfc5a3f26d911ce353f";

export default node;
