import type { ComponentProps, FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { ButtonLink, Link } from '@pafcloud/base-components';
import { camelCase } from '@pafcloud/util';
import type { FlowRouterButton_button$key } from './__generated__/FlowRouterButton_button.graphql';
import { useGetFlowUrl } from './useGetFlowUrl';

const buttonFragment = graphql`
  fragment FlowRouterButton_button on Button {
    appearanceType
    text
    ariaLabel
    iconName
    iconAlignment
    ...useGetFlowUrl_button
  }
`;

const FlowRouterLink = styled(Link)({
  display: 'block',
});

type Props = {
  button: FlowRouterButton_button$key;
  className?: string;
} & Partial<ComponentProps<typeof ButtonLink>>;

export const FlowRouterButton: FC<Props> = ({
  button,
  variant,
  fullWidth,
  size = 'default',
  iconAlignment,
  className,
  ...props
}) => {
  const data = useFragment(buttonFragment, button);
  const { text, iconName, ariaLabel, appearanceType } = data;

  const flowUrl = useGetFlowUrl({ data });

  if (flowUrl == null) {
    return null;
  }

  if (appearanceType === 'TEXT') {
    return (
      <FlowRouterLink {...props} href={flowUrl} aria-label={ariaLabel ?? ''} className={className} role="button">
        {text}
      </FlowRouterLink>
    );
  }

  return (
    <ButtonLink
      {...props}
      href={flowUrl}
      size={size}
      icon={iconName == null ? null : camelCase(iconName)}
      iconAlignment={iconAlignment}
      variant={variant}
      fullWidth={fullWidth}
      aria-label={ariaLabel ?? ''}
      className={className}
      role="button"
    >
      {text}
    </ButtonLink>
  );
};
