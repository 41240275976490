import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Link as DefaultLink } from '@pafcloud/base-components';
import type { HeroLink_contentBlock$key } from './__generated__/HeroLink_contentBlock.graphql';
import { HeroCtaButtonLink } from './hero-button/HeroCtaButtonStyle';

const contentBlockFragment = graphql`
  fragment HeroLink_contentBlock on Link {
    appearanceType
    text
    url
    ariaLabel
  }
`;

const Link = styled(DefaultLink)({
  // This needs to be able to receive margin from the parent <Stack>
  display: 'block',
  textAlign: 'center',
});

type HeroLinkComponentProps = {
  contentBlock: HeroLink_contentBlock$key;
  onHeroClick: () => void;
};

const HeroLink: FC<HeroLinkComponentProps> = ({ onHeroClick, ...props }) => {
  const contentBlock = useFragment(contentBlockFragment, props.contentBlock);

  const { appearanceType, url, text } = contentBlock;

  const ariaLabel = contentBlock.ariaLabel ?? undefined;

  if (appearanceType === 'TEXT') {
    return (
      <Link href={url} aria-label={ariaLabel} onClick={onHeroClick}>
        {text}
      </Link>
    );
  }

  return (
    <HeroCtaButtonLink href={url} aria-label={ariaLabel} size="hero" variant="primary" onClick={onHeroClick}>
      {text}
    </HeroCtaButtonLink>
  );
};

export default HeroLink;
