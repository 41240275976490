import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonLink } from '@pafcloud/base-components';
import { Color } from '@pafcloud/style';
import { FlowRouterButton } from '../../../flow/FlowRouterButton';

const heroButtonStyle = css({
  display: 'inline-flex',
  width: 'max-content',
  maxWidth: 250,
  minWidth: '10rem',

  '--button-background': Color.Hero.Button.Rest,
  '--button-background--hover': Color.Hero.Button.Hover,
  '--button-background--active': Color.Hero.Button.Active,
  '--button-text': Color.Hero.Button.Text,
  '--button-text--hover': Color.Hero.Button.Text,
  '--button-text--active': Color.Hero.Button.Text,
  '--button-border': 'transparent',
  '--button-focus-ring': Color.Hero.Button.Solid,
  '--button-shadow-tint': Color.Hero.Button.Solid + '20',
});

export const HeroCtaButtonLink = styled(ButtonLink)(heroButtonStyle);

export const HeroCtaButton = styled(FlowRouterButton)(heroButtonStyle);
